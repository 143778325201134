<template>
  <section id="error-page">
    <p>
      {{ $t('404.message') }} <span
        class="error-link"
        @click="backRoute"
      >{{ $t('404.backlink') }}</span>.
    </p>
  </section>
</template>

<script>
export default {
    metaInfo() {
        return { 
            title: 'Error 404',
        }
    },
    methods: {
        backRoute() {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
#error-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    p {
        text-align: center;
    }
}
.error-link {
    cursor: pointer;
    text-decoration: underline;
}
</style>