<template>
    <section v-if="collaborator" id="single-page">
        <ul class="collaborators-list defaultcontent-grid">

            <li v-for="item in collaborator.Collaboratorinfo" :key="item.id">
                <div class="collaborator--left">
                    <img v-if="item.logo.formats.small" :src="api_url + item.logo.formats.small.url" alt="Nitja Samarbeidspartnere">
                    <img v-else :src="api_url + item.logo.url" alt="Nitja Samarbeidspartnere">
                </div>
                <div class="collaborator--right">
                    <div class="collaborator-header">
                        <h1 v-if="$i18n.locale == 'nb'" class="title-default">{{ item.name_no }}</h1>
                        <h1 v-else class="title-default">{{ item.name_en }}</h1>

                    </div>
                    <div class="collaborator-content">
                        <MarkedParser v-if="$i18n.locale === 'nb'" :marked-content="item.content_no" class="markdown" />
                        <MarkedParser v-else :marked-content="item.content_en" class="markdown" />
                    </div>
                    <div v-if="item.website_url || item.instagram" class="collaborator-details">
                        <p>
                            <a v-if="item.website_url" :href="'https://' + item.website_url" class="metatext title-hover" target="_blank" rel="noreferrer noopener">
                                <span>{{ $t('slidepanel.website') }}</span>
                            </a>
                            <a v-if="item.instagram" :href="'https://instagram.com/' + item.instagram" class="metatext title-hover" target="_blank" rel="noreferrer noopener">
                                <span>Instagram</span>
                            </a>
                        </p>
                    </div>
                </div>
            </li>

        </ul>
    </section>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'

import gql from 'graphql-tag'

const getCollaborators = gql `
query Collaborators {
        collaborator {
            Collaboratorinfo {
              name_en
              name_no
              content_en
              content_no
              website_url
              instagram
              logo {
                formats
                url
              }
            }
        }
    }
`;

export default {
    apollo: {
        collaborator: {
            query: getCollaborators
        }
    },
    components: {
        MarkedParser
    },
    data: function() {
        return {
            ogDesc: '',
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    metaInfo() {
        return {
            title: this.$t("nav.collaborators"),
        }
    },
    computed: {
        today() {
            return format(new Date(), "dd.MM.yyyy");
        }
    },
    // updated() {
    //     let pageDescription = this.cafe.contentShort_no;
    //     this.ogDesc = pageDescription
    // },
    methods: {
        reverseMessage: function(value) {
            const mail = value.split('').reverse().join('')
            return mail
        }
    }
}
</script>