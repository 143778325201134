<template>
  <section
    v-if="privacy"
    id="single-page"
  >
    <BaseBasicPage :page-content="privacy" />
  </section>
</template>

<script>
import BaseBasicPage from '../components/base/BaseBasicPage'
import gql from 'graphql-tag'

export default {
    apollo: {
        privacy: {
            query: gql `
            query Privacy {
                privacy {
                  content_en
                  content_no
                }
            }
        `
        }
    },
    metaInfo() {
        return { 
            title: this.$t("nav.privacyPolicy"),
        }
    },
    components: {
        BaseBasicPage
    }
}
</script>