<template>
  <div id="maintenance-wrapper">
    <div id="maintenance-top" />
    <div
      v-if="info.maintenance_text_no"
      id="maintenance-middle"
    >
      <p>{{ info.maintenance_text_no }}</p>
      <p>{{ info.maintenance_text_en }}</p>
    </div>
    <div id="maintenance-bottom">
      <img
        src="../../assets/images/nitja-mainlogo-500px.svg"
        alt="Nitja senter for samtidskunst logo"
      >
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

const getInfo = gql `
  query Info {
    info {
        maintenance_boolean
        maintenance_text_no
        maintenance_text_en
      }
  }
`;

export default {
    apollo: {
        info: {
            query: getInfo
        }
    }
}
</script>