<template>
    <section v-if="publications" id="publications-overview" class="defaultcontent-grid--fullwidth">
        <div v-if="$apollo.loading">
            <div class="spinner">
                {{ $t('headers.loading') }}
            </div>
        </div>
    
        <PublicationsGrid v-if="publications && publications.length" :publications="publications" />
    
        <div id="loadmore">
            <button v-if="morePublications" class="square-btn--default" @click="loadMore">
            {{ $t('buttons.loadmorePosts') }}
          </button>
        </div>
    </section>
</template>

<script>
import PublicationsGrid from '../components/publications/PublicationsGrid';
import gql from 'graphql-tag';
import LazyLoad from 'vanilla-lazyload';

const getPublications = gql `
  query Publications ($start: Int!, $limit: Int!) {
        publications(
            start: $start, 
            limit: $limit, 
            sort: "year:desc", 
        ) {
            id
            title
            slug
            author
            publisher
            year
            content_no
            content_en
            photo_cover {
                formats
                name
                caption
            }
        }
    }
`;

const getAllPublications = gql `
  query totalCount {
    totalCount: publications {
      id
    }
  }
`;

export default {
    apollo: {
        publications: {
            query: getPublications,
            variables() {
                return {
                    limit: 9,
                    start: 0,
                }
            },
        },
        totalCount: {
            query: getAllPublications,
            result({ data }) {
                this.totalCount = data.totalCount
            },
        }
    },
    components: {
        PublicationsGrid
    },
    data: function() {
        return {
            publications: {},
            totalCount: {},
        };
    },
    metaInfo() {
        return {
            title: this.$t("nav.publications"),
        }
    },
    computed: {
        morePublications() {
            return this.totalCount.length > this.publications.length
        },
    },
    mounted() {
        this.gridLazyload = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 500,
        });
    },
    updated() {
        this.gridLazyload.update();
    },
    methods: {
        loadMore() {
            this.$apollo.queries.publications.fetchMore({
                variables: {
                    start: this.publications.length
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    return Object.assign({}, previousResult, {
                        publications: [...previousResult.publications, ...fetchMoreResult.publications]
                    })
                }
            })
        }
    }
}
</script>