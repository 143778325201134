<template>
    <div id="collection" class="defaultcontent-grid">
        <div class="posts-grid">
            <div v-for="publication in publications" :key="publication.slug" :viewPublication="publication.slug" class="post-block post-block--publications" @click="pushToPanel(publication.slug, 'getPublication')">
                <div id="post-content">
                    <div id="post-thumbnail">
                        <img v-if="publication.photo_cover.formats.small" :data-src="api_url + publication.photo_cover.formats.small.url" :alt="publication.title" class="post-thumbnail--publications lazy">
                        <img v-else-if="publication.photo_cover.formats.thumbnail" :data-src="api_url + publication.photo_cover.formats.thumbnail.url" :alt="publication.title" class="post-thumbnail--publications lazy">
                        <div v-else class="post-thumbnail--empty" />
                    </div>
                    <div id="post-details">
                        <p class="title-default">
                            <span>
                    {{ publication.title }}
                  </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
        <SlidePanel ref="panel" />
    </div>
</template>

<script>
import SlidePanel from "@/components/SlidePanel"

export default {
    components: {
        SlidePanel
    },
    props: {
        publications: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            isPanelVisible: false,
        };
    },
    methods: {
        pushToPanel: function(passSlug, getType) {
            this.$refs.panel.openPanel(passSlug, getType);
        }
    },
};
</script>
