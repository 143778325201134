<template>
  <BaseMaintenance v-if="info" />
</template>

<script>
import BaseMaintenance from '@/components/base/BaseMaintenance'
import gql from 'graphql-tag'

const getInfo = gql `
  query Info {
    info {
        maintenance_boolean
        maintenance_text_no
        maintenance_text_en
      }
  }
`;

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    components: {
        BaseMaintenance
    }
}
</script>